import React from 'react';
import styles from "../../../views/users/users.module.scss";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const UserActions = (props) => {
    
    return (
        <td className="bodyText text-center">
            <OverlayTrigger
            key={'top'}
            placement='top'
            overlay={
            <Tooltip id={`tooltip-top`}>
            Click to view
            </Tooltip>
            }>
                {/* <Link to={`/app/users/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                    <span className={styles.actionBtn}>
                    <i class="far fa-eye" onClick={() => props.context.handleViewFunc(props.data.actualData)}></i>
                    </span>
                       {/* </Link> */}
                    </OverlayTrigger>

            <OverlayTrigger
                key={'bottom'}
                placement='bottom'
                overlay={
                <Tooltip id={`tooltip-bottom`}>
                    Click to edit
                </Tooltip>}>
                    {/* <Link to={`/app/usersEdit/${ele.agentId}`} style={{ color: '#272b41' }}> */}
            <span className={styles.actionBtn}>
                <i class="fas fa-edit" onClick={() => props.context.handleEditFunc(props.data.actualData)}></i>
             </span>
                    {/* </Link> */}
            </OverlayTrigger>

                                                        {/* <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    Click to delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteOpen(ele)}></i>
                                                        </OverlayTrigger> */}

            {props.data.actualData.enabledStatus === 1 && <button type="button" class="btn btn-success" disabled={props.context.toggleStatus} onClick={() => props.context.handleDeleteOpen(props.data.actualData, 'Disable')}>Enabled</button>}
            {props.data.actualData.enabledStatus === 0 && <button type="button" class="btn btn-danger" disabled={props.context.toggleStatus} onClick={() => props.context.handleDeleteOpen(props.data.actualData, 'Enable')}>Disabled</button>}
        </td>
    );
}

export default UserActions;
