import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

const DeleteuserComponent = ({ token, show, onHide, onAccept, userStatus }) => {
    return (
        <Modal
            size='md'
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5> {`Are you sure you want to ${userStatus} the user ?`}</h5>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={onHide}>No</button>
                <button className='btn customButton acceptButton' onClick={onAccept}>Yes</button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteuserComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept
});

export default connect(mapStateToProps, null)(DeleteuserComponent);