/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './servicePlan.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteuserComponent from './delete-user-modal';
import { userMenuDetails } from '../../redux/userMenu/userMenu.actions';
import { API_APP_ADD_SUBSCRIPTION, API_APP_DELETE_SUBSCRIPTION, API_APP_GET_SUBSCRIPTION, API_APP_UPDATE_SUBSCRIPTION, IsNum_REGEX } from '../../utils/consts/api';

const ServicePlanComponent = ({ token }) => {
    const [planList, setPlanList] = useState([]);
    const addPlanIntialValues = {
        planTitle: "",
        planValue: '',
        description: "",
        months: ""
    };
    const updatePlanIntialValues = {
        planTitle: "",
        planValue: '',
        planId:'',
        description: "",
        months: ""
    };
    const [ addPlanValues,setAddPlanValues ] = useState(addPlanIntialValues);
    const [ updatePlanValues,setUpdatePlanValues ] = useState(updatePlanIntialValues);
    const [show, setShow] = useState(false);
    const [updateShow, setUpdateShow] = useState(false);
    const [checkedState,setCheckedState] = useState(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = useState(false);
    const handleUpdateShow = () => setUpdateShow(true);
    const [deletePlanID,setDeletePlanID] = useState('');

    const getAllUsersList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.get(API_APP_GET_SUBSCRIPTION, { headers });
            if (res.data.responseCode === 0) {
                setPlanList(res.data.response.subscriptionList);
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    useEffect(() => {
        getAllUsersList();
    }, []);

    const handleEditFunc = (updateData) => {
        setUpdateShow(true);
        setUpdatePlanValues({...updatePlanValues,planTitle:updateData.planTitle,description:updateData.planDescription,
            planValue:updateData.planValue,planId:updateData.planId,months:updateData.noOfMonths});
    };

    const handleDeleteOpen = (ele) => {
        setDeletePlanID(ele.planId);
        setModalShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleUpdateClose = () =>{
        setUpdateShow(false);
        setUpdatePlanValues(updatePlanIntialValues);
    };

    const handleAddPlanValues = (e)=>{       
       const {name,value} = e.target;
       
       if(name === "planValue"){
         if(IsNum_REGEX.test(value)){
            setAddPlanValues({...addPlanValues,[name]:value});
         }else{
            setAddPlanValues({...addPlanValues,[name]:value.slice(0,-1)});
         }
         return;
       }
       setAddPlanValues({...addPlanValues,[name]:value});
    };

    const handleCheckedBox = (e)=>{
        const {checked} = e.target;
        if(checked){
          if(!checkedState){
            setCheckedState(true);
            setAddPlanValues({...addPlanValues,planValue:0});
          }
        }else{
            setAddPlanValues({...addPlanValues,planValue:''});
            setCheckedState(false);
        }
    };

    const handleAddPlanSubmission = async (e) => {
        e.preventDefault();
        if(!addPlanValues.planValue){
            if(Number(addPlanValues.planValue) !== 0){
                toast.error('Please enter the amount', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            };
        };
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_APP_ADD_SUBSCRIPTION,addPlanValues, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getAllUsersList();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setAddPlanValues(addPlanIntialValues);
        setShow(false);
        setCheckedState(false);
    };

    const handleUpdatePlanValues = (e)=>{
        const {name,value} = e.target;
        
        if(name === "planValue"){
           if(IsNum_REGEX.test(value)){
              setUpdatePlanValues({...updatePlanValues,[name]:value});
           }else{
              setUpdatePlanValues({...updatePlanValues,[name]:value.slice(0,-1)});
           }
           return;
        }       
        setUpdatePlanValues({...updatePlanValues,[name]:value});
     };

    const handleUpdatePlanSubmission = async (e) => {
        e.preventDefault();
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_APP_UPDATE_SUBSCRIPTION,updatePlanValues, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getAllUsersList();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setUpdateShow(false);
    };

    const handleDeleteclose = () => {
        setModalShow(false);
    };

    const handleDeleteaccept = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_APP_DELETE_SUBSCRIPTION,{planId:deletePlanID}, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setModalShow(false);
                getAllUsersList();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setDeletePlanID('');
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        };
    };

    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <div className='d-flex justify-content-between'>
                        <h5 className="customTitleName">Service Plans</h5>
                        <button className={styles.addNewPlan} onClick={handleShow}>Add New Plan</button>
                    </div>
                    <div className={`card ${styles.planCard}`}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">Plan Title</th>
                                                    <th className="headText">Value</th>
                                                    <th className="headText">Description</th>
                                                    <th className="headText">Status</th>
                                                    <th className="headText">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                                {planList && planList.length > 0 && planList.map((ele) => (<tr>
                                                    <td className="bodyText">{ele.planTitle ? ele.planTitle === "free" ? "Trial Plan" : ele.planTitle : '-'}</td>
                                                    <td className="bodyText">{ele.planValue ? `INR ${ele.planValue} Per month` : 'FREE'}</td>
                                                    <td className="bodyText">{ele.planDescription ? ele.planDescription : '-'}</td>
                                                    <td style={ele.status === "active" ? { color: "green" } : null}>
                                                        {ele.status}
                                                    </td>
                                                    <td className="bodyText">
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    Click to edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className={styles.actionBtn}>
                                                                <i class="fas fa-edit" onClick={() => handleEditFunc(ele)}></i>
                                                            </span>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    Click to delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteOpen(ele)}></i>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>))}
                                                {planList && planList.length === 0 &&
                                                    <div className="text-center w-100">
                                                        <h4>No Data Found</h4>
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add New Plan Modal Here */}

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.modalOterDiv}
                show={show}
                onHide={handleClose}
            >

                <div className={styles.modalmainDiv}>
                    <div className={styles.modalDiv}>
                        <div className={`${styles.modalTitle}`}>Add New Plan</div>
                        <div className={styles.closeButtonDiv}>
                            <div className={`${styles.closeButton}`} onClick={handleClose}>
                                x
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={styles.borderLine} style={{ marginBottom:'0px' }}/>
                <Modal.Body className={`${styles.modalBody}`}>
                    <div className={styles.midSection}>
                        <form onSubmit={handleAddPlanSubmission}>
                            <div className={styles.addPlanDiv}>
                                <span className={styles.addPlanText}>Plan Title</span>
                                <input className={styles.addPlanInput} type='text' 
                                       required placeholder='Enter Plan Title'
                                       value={addPlanValues.planTitle}
                                       name='planTitle' onChange={handleAddPlanValues}
                                />
                            </div>
                            <div>
                                <div className={`${styles.addPlanDiv}`} style={{ marginTop:'8px' }}>
                                    <label className={styles.addPlanText} >Value (in INR)</label>
                                    <div className='d-flex gap-4'>
                                        <div className={`w-50 ${styles.addPlanDiv}`}>
                                            <input className={styles.addPlanInput} type='text' placeholder='Enter Value'
                                                   name='planValue'  onChange={handleAddPlanValues}
                                                   value={addPlanValues.planValue}
                                                   disabled={checkedState}
                                            />
                                        </div>
                                        <div className={`w-50 ${styles.addPlanDiv}`}>
                                            <select className={`form-select ${styles.addPlanInput}`} required name='months' onChange={handleAddPlanValues} >
                                                <option value="">Month</option>
                                                <option value="1">1</option>
                                                <option value="3">3</option>
                                                <option value="6">6</option>
                                                <option value="9">9</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop:'16px' }}>
                                <input type="checkbox" class="form-check-input" onChange={handleCheckedBox} disabled={addPlanValues.planValue}
                                    style={{ boxShadow: 'none', border: '2px solid #272B41' }} />
                                <span style={{ margin: '0px 0px 0px 15px',fontWeight:'500' }}>Its a Free Plan</span>

                            </div>
                            <div className={styles.addPlanDiv } style={{ marginTop:'20px' }}>
                                <label className={styles.addPlanText}>Description</label>
                                <textarea type="text" style={{ height:'170px' }} className={styles.addPlanInput} required placeholder='Enter here...'
                                 name = 'description'  onChange={handleAddPlanValues}
                                />
                            </div>
                            <div className={styles.buttonDiv}>
                                <button className={styles.uploadButton} type="submit" >Add Plan</button>
                                <button className={styles.cancelButton} type="button" onClick={handleClose}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>

            {/* Update Plan Modal Here */}

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.modalOterDiv}
                show={updateShow}
                onHide={handleUpdateClose}
            >

                <div className={styles.modalmainDiv}>
                    <div className={styles.modalDiv}>
                        <div className={`${styles.modalTitle}`}>Update Plan</div>
                        <div className={styles.closeButtonDiv}>
                            <div className={`${styles.closeButton}`} onClick={handleUpdateClose}>
                                x
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={styles.borderLine} style={{ marginBottom: '0px' }} />
                <Modal.Body className={`${styles.modalBody}`}>
                    <div className={styles.midSection}>
                        <form onSubmit={handleUpdatePlanSubmission}>
                            <div className={styles.addPlanDiv}>
                                <label className={styles.addPlanText}>Plan Title</label>
                                <input className={styles.addPlanInput} type='text' required placeholder='Enter Plan Title'
                                 name='planTitle' onChange={handleUpdatePlanValues} defaultValue = {updatePlanValues.planTitle}
                                />
                            </div>
                            <div>
                                <div className={`${styles.addPlanDiv}`} style={{ marginTop:'8px' }}>
                                    <label className={styles.addPlanText} >Value (in INR)</label>
                                    <div className='d-flex gap-4'>
                                        <div className={`w-50 ${styles.addPlanDiv}`}>
                                            <input className={styles.addPlanInput} type='text' placeholder='Enter Value'
                                                   value={updatePlanValues.planValue}
                                                   name='planValue'  onChange={handleUpdatePlanValues} defaultValue = {updatePlanValues.planValue}
                                            />
                                        </div>
                                        <div className={`w-50 ${styles.addPlanDiv}`}>
                                            <select className={`form-select ${styles.addPlanInput}`} defaultValue={updatePlanValues.months} name='months' onChange={handleUpdatePlanValues}>
                                                <option value="">Months</option>
                                                <option value="1">1</option>
                                                <option value="3">3</option>
                                                <option value="6">6</option>
                                                <option value="9">9</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.addPlanDiv} style={{ marginTop:'20px' }}>
                                <label className={styles.addPlanText}>Description</label>
                                <textarea type="text" className={styles.addPlanInput} required style={{ height: '180px', marginTop: '10px' }} placeholder='Enter here'
                                 name = 'description'  onChange={handleUpdatePlanValues} defaultValue = {updatePlanValues.description}
                                />
                            </div>
                            <div className={styles.buttonDiv}>
                                <button className={styles.uploadButton} type="submit" style={{ width:'150px' }}>Update Plan</button>
                                <button className={styles.cancelButton} type="button" onClick={handleUpdateClose}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>

            <DeleteuserComponent
                show={modalShow}
                onHide={handleDeleteclose}
                onAccept={handleDeleteaccept}
            />
        </>
    );
};

ServicePlanComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state) => ({
    token: state.user.token
});

export default connect(mapStateToProps, { userMenuDetails })(ServicePlanComponent);