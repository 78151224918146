/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./payment.module.scss";
import axios from "axios";
import {
  API_APP_POST_ADDEDITCOMMENTDATA,
  API_APP_GET_PaymentInfo,
  API_APP_MOVE_PAYMENTONHOLD,
  API_APP_MOVE_PAYMENTOPAID,
} from "../../utils/consts/api";
import dummy from "../../assets/images/default-Profile-Pic.jpg";
import { toast } from "react-toastify";
import { Modal, OverlayTrigger, Popover, Spinner, Table, Tooltip } from "react-bootstrap";
import DeleteuserComponent from "./hold-user-modal";
import MovePaymentComponent from "./paid.user.modal";
import { userMenuDetails } from "../../redux/userMenu/userMenu.actions";
import * as moment from "moment";
import { BiRupee } from "react-icons/bi";
import { BiPhone } from "react-icons/bi";
import { MdOutlineInfo } from "react-icons/md";
import { B2BFields } from "../../components/Table/demoData";
import AgTable from "../../components/Table/AgTable";
import SkeletonLoader from "../../components/Loader";


const B2BPayments = ({ token, userMenuDetails }) => {
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [spinner,setSpinner]=useState(false);
  const pageLimit = useRef(5);
  const pageNo = useRef(0);
  const [pageNoMax] = useState(10000000);
  const [cacheNextUserList, setcacheNextUserList] = useState([]);
  const [cachePrevUserList, setcachePrevUserList] = useState([]);
  const [addEditCommentshow, setAddEditCommentshow] = useState(false);
  const [seletedObj, setSelectedObject] = useState({});
  const [CommentData, setCommentData] = useState({
    bookingId: null,
    adminResponse: ""
  });
  const [modalShow, setModalShow] = useState(false);
  const [paidShow, setPaidShow] = useState(false);
  const seletedBookingID = useRef("");
  const [isPaginationToDisabled, setIsPaginationToDisabled] = useState(false);
  

  // Starting of Functions related to gets all user list and pagination handling,Filteration and searching of data

  const paymentDetailsFilterFunction = (paymentArray) => {
    return paymentArray.filter((ele) => {
      return (
        ele.platformType !== "b2c"
      )
    })
  }

  const getNextPrevUsersList = async (whichPage = "next", searchValue) => {
    whichPage = pageNo.current === 0 ? "next" : whichPage;

    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const pageToPassInAPI = whichPage === "next" ? pageNo.current + 1 : pageNo.current - 1;
      const res = await axios.post(
        API_APP_GET_PaymentInfo,
        { page: pageToPassInAPI, size: pageLimit.current, searchString: searchValue ? searchValue : query },
        { headers }
      );
      if (res.data.responseCode === undefined) {
        setIsPaginationToDisabled(true);
      }
      if (res.data.responseCode === 0) {
        setIsPaginationToDisabled(false);
        const paymentsFilterArray = paymentDetailsFilterFunction(res.data.response.paymentDetails);
        if (whichPage === "next") {
          if ((res.data.response?.paymentDetails.length === 0) || (res.data.responsea?.paymentDetails.length < pageLimit.current)) {
            setIsPaginationToDisabled(true);
          }
          setcacheNextUserList(
            paymentsFilterArray
          );
        } else {
          setcachePrevUserList(
            paymentsFilterArray
          );
        }
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getAllUsersList = async (searchValue) => {
    
    setSpinner(true);

    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const res = await axios.post(
        API_APP_GET_PaymentInfo,
        { 
          // page: pageNo.current, 
          // size: pageLimit.current,
          // size:200,
          // searchString: searchValue ? '' : query 
        },
        { headers }
      );
      if (res.data.responseCode === 0) {
        const paymentsFilterarray = paymentDetailsFilterFunction(res.data.response.paymentDetails)
       
        setpaymentDetails(paymentsFilterarray);
        setSpinner(false);
        if (
          res.data.response?.paymentDetails.length === pageLimit.current &&
          cacheNextUserList.length === 0
        ) {
          getNextPrevUsersList();
        }
      } else {

        setSpinner(false);

        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {

      setSpinner(false);

      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const nextCacheSet = () => {
    setcachePrevUserList(paymentDetails);
    setpaymentDetails(cacheNextUserList);
    setcacheNextUserList([]);
    getNextPrevUsersList("next");
  };

  const prevCacheSet = () => {
    setcacheNextUserList(paymentDetails);
    setpaymentDetails(cachePrevUserList);
    setcachePrevUserList([]);
    getNextPrevUsersList("prev");
  };


 

  useEffect(() => {
    getAllUsersList();
  }, [pageLimit.current]);

  const resetCache = () => {
    pageNo.current = 0;
    setcacheNextUserList([]);
    setcachePrevUserList([]);
  };

  const nextPrev = (val) => {

    if (val === "next") {
      if (pageNo.current < pageNoMax) {
        pageNo.current = pageNo.current + 1;
        nextCacheSet();
      }
    } else {
      if (pageNo.current >= 1) {
        pageNo.current = pageNo.current - 1;
        prevCacheSet();
      }
    }
  };

  const entriesPerPage = (e) => {
    pageLimit.current = parseInt(e.target.value, 10);
    resetCache();
  };
  
  const handleSubmit = async (searchValue) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const res = await axios.post(
        API_APP_GET_PaymentInfo,
        { page: 0, size: pageLimit.current, searchString: searchValue ? searchValue : query },
        { headers }
      );
      if(res.data.responseCode === undefined){
        setIsPaginationToDisabled(true);
      }
      if (res.data.responseCode === 0) {
        const paymentsFilterArray = paymentDetailsFilterFunction(res.data?.response.paymentDetails);
        setpaymentDetails(paymentsFilterArray);
        if (
          res.data.response.paymentDetails.length === pageLimit.current
        ) {
          getNextPrevUsersList('next',searchValue);
        }
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Ending of Functions related to gets all user list and pagination handling,Filteration and searching of data

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const handleDeleteOpen = (data) => {
    setSelectedObject(data);
    setModalShow(true);
  };


  //  handler for Hold or Release 
  const handleDeleteaccept = async () => {
    
   
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const res = await axios.post(
        API_APP_MOVE_PAYMENTONHOLD,
        { bookingId: seletedObj.bookingId },
        { headers }
      );
      if (res.data.responseCode === 0) {

     let newPaymentDetails=paymentDetails.map((ele)=>{
          
      if(ele.bookingId==seletedObj.bookingId){
                ele.paymentStatus='hold'
                return ele;
       }
        return ele
     })

      setpaymentDetails((prev)=>{
        return [...prev,newPaymentDetails];
      })
        
        toast.success("Payment move on Hold", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSelectedObject(null);
        setModalShow(false);
        resetCache();
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

 
  const handleDeleteclose = () => {
    setSelectedObject(null);
    setModalShow(false);
  };

  const [query, setQuery] = useState("");

  const searchItems = (e) => {
    setQuery(e.target.value);
    if (e.target.value.length >= 3) {
      handleSubmit(e.target.value);
      pageNo.current = 0;
    } else if (e.target.value.length === 0) {
      getAllUsersList(true);
    }
  };


  //handler for edit modal of Hold or release.
  const handleEditFunc = (bookingID) => {
    
    seletedBookingID.current = bookingID;
    
    setPaidShow(true);
  };

  const handleMoveclose = () => {
    seletedBookingID.current = "";
    setPaidShow(false);
  };



  // handler for the payment failed.
  const handleMoveaccept = async () => {
     
      
      

    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const res = await axios.post(
        API_APP_MOVE_PAYMENTOPAID,
        { bookingId: seletedBookingID.current },
        { headers }
      );
      if (res.data.responseCode === 0) {

    let newPaymentDetails=paymentDetails.map((ele)=>{
          
      if(ele.bookingId==seletedBookingID.current){
                ele.paymentStatus='paid'
                return ele;
       }
        return ele
     })

      setpaymentDetails((prev)=>{
        return [...prev,newPaymentDetails];
      })        


        toast.success("Payment move to Paid", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        seletedBookingID.current = "";
        setPaidShow(false);
        resetCache();
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleAddEditCommentClose = () => {
    setAddEditCommentshow(false);
  };

  const addEditCommentDataFn = async (e) => {
    e.preventDefault();

    if (!CommentData.bookingId) {
      toast.error("Please try again", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const customResult = await axios.post(
        API_APP_POST_ADDEDITCOMMENTDATA,
        CommentData,
        { headers }
      );
      if (customResult.data.responseCode === 0) {
        toast.success(customResult.data.successMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllUsersList();
        handleAddEditCommentClose();
      } else {
        toast.error(customResult.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleAddEditCommentClose();
    }
  };

  const handleAddEditcommentChange = (e) => {
    const { name, value } = e.target;
    setCommentData({ ...CommentData, [name]: value });
  };

  const handleAddEditCommentData = (selectedelement) => {
    
    setSelectedObject(selectedelement);
    setCommentData({ ...CommentData, bookingId: selectedelement.bookingId })
    setAddEditCommentshow(true);
  };



  useEffect(()=>{
    
  },[paymentDetails]);
  return (
    <>
      <div className="row m-0">
        <div className="col-12" style={{height:'95vh'}}>
            <h5 className="customTitleName">B2B Payments</h5>
          {/* <div className="d-flex"> */}
            {/* <p className={`ms-3 ${styles.userSearchText}`}>
              {paymentDetails?.length} Payments{" "}
            </p> */}

            {/* Search */}
            {/* <div class="col-md-8">
              <div class="d-flex form-inputs">
                <input
                  className={`form-control ${styles.searchBar}`}
                  type="text"
                  onChange={searchItems}
                  placeholder="Search..."
                  value={query}
                />
                <i
                  class="fa fa-search"
                  style={{
                    marginLeft: "-30px",
                    opacity: 0.5,
                    marginTop: "22px",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                ></i>
              </div>
            </div> */}
          {/* </div> */}

          {/* Table Data  */}
          {/* <div className={`card ${styles.customCard}`}>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-borderless custumTable">
                      <thead className="tablehead">
                        <tr>
                          <th className={`headText ${styles.bookingIDcell}`}>
                            ID
                          </th>
                          <th className="headText">Date</th>
                          <th className="headText">Posted By</th>
                          <th className="headText">Recieved By</th>
                          <th className="headText">Status</th>
                          <th className="headText">Total Amount</th>
                          <th className="headText">Our Commision</th>
                          <th className="headText text-center">
                            Hold or Release Payment
                          </th>
                          <th className="headText">Comment History</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {paymentDetails &&
                          paymentDetails.length > 0 &&
                          paymentDetails.map((ele) => (
                            <tr key={ele.bookingId}>
                              <td className={`bodyText`}>
                                {ele.bookingId ? ele.bookingId : "-"}
                              </td>
                              <td className="bodyText">
                                {moment(
                                  new Date(ele.paymentReceivedDate)
                                ).format("MMMM D, Y")}
                              </td>
                              <td className="bodyText">
                                <div className="d-flex w-100 align-items-center">
                                  <div>
                                    <img
                                      src={ele.postedAgentImage}
                                      onError={handleImageErr}
                                      alt=""
                                      width="38"
                                      height="38"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                  <div className="ms-2">
                                    {ele.postedFullName ? <OverlayTrigger
                                      key={'left'}
                                      placement='left'
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          {ele.postedFullName}
                                        </Tooltip>
                                      }
                                    >
                                      <h6 className="m-0 bodyTextEllipsis" style={{ width: '120px' }}>
                                        {ele.postedFullName}
                                      </h6>
                                    </OverlayTrigger> : <h6 className="m-0">-</h6>}
                                    <h6 className="m-0 mt-1 bodyText" style={{ fontSize: '11.2px' }}>
                                      <BiPhone /> {ele.postedAgentNo ? ele.postedAgentNo : '-'}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td className="bodyText">
                               
                                <div className="d-flex w-100 align-items-center">
                                  <div>
                                    <img
                                      src={ele.receivedAgentImage}
                                      onError={handleImageErr}
                                      alt=""
                                      width="38"
                                      height="38"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                  <div className="ms-2">
                                    {ele.receivedFullName ? <OverlayTrigger
                                      key={'left'}
                                      placement='left'
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          {ele.receivedFullName}
                                        </Tooltip>
                                      }
                                    >
                                      <h6 className="m-0 bodyTextEllipsis" style={{ width: '120px' }}>
                                        {ele.receivedFullName}
                                      </h6>
                                    </OverlayTrigger> : <h6 className="m-0">-</h6>}
                                    <h6 className="m-0 mt-1 bodyText" style={{ fontSize: '11.2px' }}>
                                      <BiPhone /> {ele.receiveAgentNo ? ele.receiveAgentNo : '-'}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td className={`bodyText `}>
                                <div
                                  className={`${ele.paymentStatus === "success"
                                    ? styles.successStatus
                                    : ele.paymentStatus === "processing"
                                      ? styles.processingStatus
                                      : ele.paymentStatus === "hold"
                                        ? styles.holdStatus
                                        : ele.paymentStatus === "failed"
                                          ? styles.failedStatus
                                          : ele.paymentStatus === "refunded"
                                            ? styles.refundedStatus
                                            : ele.paymentStatus === "cancelled"
                                              ? styles.cancelledStatus
                                              : ""
                                    }`}
                                >
                                  <p
                                    style={{ marginBottom: "0px" }}
                                    className={`${ele.paymentStatus === "success"
                                      ? styles.successStatusText
                                      : ele.paymentStatus === "processing"
                                        ? styles.processingStatusText
                                        : ele.paymentStatus === "hold"
                                          ? styles.holdStatusText
                                          : ele.paymentStatus === "failed"
                                            ? styles.failedStatusText
                                            : ele.paymentStatus === "refunded"
                                              ? styles.refundedStatusText
                                              : ele.paymentStatus === "cancelled"
                                                ? styles.cancelledStatusText
                                                : ""
                                      }`}
                                  >
                                    {ele.paymentStatus
                                      ? ele.paymentStatus
                                      : "-"}
                                  </p>
                                </div>
                              </td>
                              <td className="bodyText">
                                <div className={`${styles.amountDiv}`}>
                                  <BiRupee style={{ marginTop: "-1px" }} />
                                  {ele.bookingCommission
                                    ? ele.bookingCommission
                                    : "-"}
                                </div>
                              </td>
                              <td className="bodyText">
                                <div className={`${styles.amountDiv}`}>
                                  <BiRupee style={{ marginTop: "-1px" }} />
                                  {ele.taxiSanchalakCommission
                                    ? ele.taxiSanchalakCommission
                                    : "-"}
                                </div>
                              </td>
                              <td>
                                <div className={`${styles.buttonDiv}`}>
                                  {ele.paymentStatus === "processing" ? (
                                    <button
                                      className={`${styles.button}`}
                                      style={{ textAlign: "center" }}
                                      onClick={() =>
                                        handleDeleteOpen(ele)
                                      }
                                    >
                                      Hold
                                    </button>
                                  ) : ele.paymentStatus === "hold" ? (
                                    <button
                                      className={`${styles.paymentHoldButton}`}
                                      style={{ textAlign: "center" }}
                                      disabled
                                    >
                                      Payment on Hold{" "}
                                    </button>
                                  ) : ele.paymentStatus === "paid" ? (
                                    <p className={`${styles.paidToVendor}`}>
                                      Paid to Vendor{" "}
                                    </p>
                                  ) : ele.paymentStatus === "refunded" ? (
                                    <p className={`${styles.refunded}`}>
                                      {" "}
                                      Refunded{" "}
                                    </p>
                                  ) : ele.paymentStatus === "success" ? (
                                    <p className={`${styles.success}`}>
                                      {" "}
                                      Completed{" "}
                                    </p>
                                  ) : ele.paymentStatus === "cancelled" ? (
                                    <p className={`${styles.cancelled}`}>
                                      {" "}
                                      Cancelled{" "}
                                    </p>
                                  ) :
                                    ele.paymentStatus === "failed" ?
                                      <div
                                        className={`${styles.paymentFailedDiv}`}
                                      >
                                        <p
                                          style={{ marginBottom: "0px" }}
                                          className={`${styles.paymentFailedText}`}
                                        >
                                          Payment failed
                                        </p>
                                        <span
                                          className="bodyText text-center"
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <OverlayTrigger
                                            key={"top"}
                                            placement="left"
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header as="h3">
                                                  Payment Failed Cause
                                                </Popover.Header>
                                                <Popover.Body>
                                                  {ele.paymentFailedReason
                                                    ? ele.paymentFailedReason
                                                    : "Reason is not specify"}
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <i
                                              class={`far fa-eye ${styles.actionBtn}`}
                                            ></i>
                                          </OverlayTrigger>
                                        </span>
                                        <span>
                                          <OverlayTrigger
                                            key={"bottom"}
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id={`tooltip-bottom`}>
                                                Click to edit
                                              </Tooltip>
                                            }
                                          >
                                            <span className={styles.actionBtn}>
                                              <i
                                                class="fas fa-edit"
                                                onClick={() =>
                                                  handleEditFunc(ele.bookingId)
                                                }
                                              ></i>
                                            </span>
                                          </OverlayTrigger>
                                        </span>
                                      </div>
                                      : "-"
                                  }
                                </div>
                              </td>
                              <td>
                                <div className={`${styles.buttonDiv}`}>
                                  <div>
                                    {
                                      ele?.queryComments !== "null" ?
                                        <span
                                          className="bodyText text-center"
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <OverlayTrigger
                                            key={"top"}
                                            placement="left"
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header as="h3">
                                                  Comment
                                                </Popover.Header>
                                                <Popover.Body>
                                                  {ele?.queryComments}
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <i
                                              class={`far fa-eye ${styles.actionBtn}`}
                                            ></i>
                                          </OverlayTrigger>
                                        </span>
                                        :
                                        ''
                                    }
                                    <span>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {ele?.queryComments === "null" ? "Click to edit" : "Click to Update"}
                                          </Tooltip>
                                        }
                                      >
                                        <span className={styles.actionBtn}>
                                          <i
                                            class="fas fa-edit"
                                            onClick={() =>
                                              handleAddEditCommentData(ele)
                                            }
                                          ></i>
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {paymentDetails && paymentDetails.length === 0 && (
                          <div className="text-center w-100">
                            <h4>No Data Found</h4>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* pagination div */}
          {/* <div className="col-12">
            <div className="w-100 customPginationDiv">
              <h6 className="customText">Items per page</h6>
              <select
                className="form-select customSelectWidth"
                name="pageLimit"
                onChange={entriesPerPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <button
                className="btn customButtons buttonMargin"
                disabled={pageNo.current === 0}
                onClick={() => nextPrev("prev")}
              >
                <i className="fas fa-angle-left"></i>
              </button>

              <button
                className="btn customButtons"
                disabled={isPaginationToDisabled}
                onClick={() => nextPrev("next")}
              >
                <i className="fas fa-angle-right"></i>
              </button>
            </div>
          </div> */}

          {/* //ag-table */}
        
          <hr/>          
      {/* <AgTable colDefs={B2BFields} rowData={paymentDetails}/> */}

        
        
      {
        spinner&&paymentDetails.length==0?
        <SkeletonLoader/> :
      <AgTable
       rowData={paymentDetails}
       colDefs={B2BFields}
       section={"B2BRows"}
       handleDeleteOpen={handleDeleteOpen}
       handleDeleteclose={handleDeleteclose}
       handleEditFunc={handleEditFunc}
       handleDeleteaccept={handleDeleteaccept}
       handleAddEditCommentData={handleAddEditCommentData}
       handleImageErr={handleImageErr}
       modalShow={modalShow}
      />
      }

        </div>        
      </div>

{/* ------------------------------------------------------------ */}
      {/* Add Comment modal starts here */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.modalOterDiv}
        show={addEditCommentshow}
        onHide={handleAddEditCommentClose}
      >
        <div className={styles.modalmainDiv}>
          <div className={styles.modalDiv}>
            <div className={`${styles.modalTitle}`}>
              {seletedObj?.queryComments === "null" ? " Add Comment here" : "Update the comment"}
            </div>
            <div className={styles.closeButtonDiv}>
              <div
                className={`${styles.closeButton}`}
                onClick={handleAddEditCommentClose}
              >
                x
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.borderLine} style={{ marginBottom: "0px" }} />
        <Modal.Body className={`${styles.modalBody}`}>
          <div className={styles.midSection}>
            <form onSubmit={addEditCommentDataFn}>
              <div className={styles.addPlanDiv}>
                <label className={styles.addPlanText}>Description</label>
                <textarea
                  type="text"
                  style={{ height: "170px" }}
                  className={styles.addPlanInput}
                  defaultValue={seletedObj ? seletedObj.queryComments : null}
                  required
                  placeholder="Enter here..."
                  name="adminResponse"
                  onChange={handleAddEditcommentChange}
                />
              </div>
              <div className={styles.paymentButtonDiv}>
                <button className={styles.uploadButton} type="submit">
                  {seletedObj?.queryComments === "null" ? "Add Comment" : "Update Comment"}
                </button>
                <button
                  className={styles.cancelButton}
                  type="button"
                  onClick={handleAddEditCommentClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add comment modal ends here */}

      <DeleteuserComponent
        show={modalShow}
        onHide={handleDeleteclose}
        onAccept={handleDeleteaccept}
      />

      <MovePaymentComponent
        show={paidShow}
        onHide={handleMoveclose}
        onAccept={handleMoveaccept}
      />


    
    <div>
    </div>
    </>
  );
};

B2BPayments.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  token: state.user.token,
});

export default connect(mapStateToProps, { userMenuDetails })(B2BPayments);
